const List = {
	MuiMenu: {
		styleOverrides: {
			list: {
				borderRadius: 0,
				border: '1px solid var(--color-grey70)',
				marginTop: 'var(--spacing-1)',
				padding: 0,
				clipPath: 'polygon(calc(100% - 18px) 0, 100% 18px, 100% 100%, 0 100%, 0 0)',
				'&:after': {
					content: `""`,
					display: 'block',
					height: '1px',
					width: '26px',
					border: '1px solid var(--color-grey70)',
					position: 'absolute',
					transform: 'rotate(45deg)',
					top: '7px',
					right: '-5px',
				},
			},
		},
	},
	MuiMenuItem: {
		defaultProps: {
			disableRipple: true,
		},
		styleOverrides: {
			root: {
				// color: globalTheme.palette.primary.main,
				padding: 'var(--spacing-2) var(--spacing-1)',
				borderBottom: 'none',
				whiteSpace: 'normal',
				'&:not(.Mui-disabled)': {
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,

					'&:hover': {
						borderTopLeftRadius: 0,
						borderTopRightRadius: 0,
					},
				},
				'&:last-of-type': {
					borderBottom: 0,
					borderBottomLeftRadius: 0,
					borderBottomRightRadius: 0,
				},
				// '&.Mui-disabled': {
				// 	color: globalTheme.palette.grey.main,
				// },
				// '&.Mui-selected': {
				// 	backgroundColor: globalTheme.palette.ghost.hover,
				// 	color: globalTheme.palette.grey.main,
				// },
				// '&.Mui-focusVisible': {
				// 	backgroundColor: globalTheme.palette.ghost.hover,
				// 	color: globalTheme.palette.primary.main,
				// },
				// '&:hover': {
				// 	backgroundColor: globalTheme.palette.ghost.hover,
				// 	color: globalTheme.palette.grey.main,
				// },
			},
		},
	},
}

export default List
