// import defaultTheme from '@bluheadless/ui/src/theme/default'
import { fonts } from '../fonts'

const commonStyles = {
	minHeight: '36px',
	minWidth: 'fit-content',
	borderRadius: '0px',
	textTransform: 'uppercase',
	fontFamily: 'var(--font-alternative-regular)',
	fontSize: 'var(--button-primary-size)',
	lineHeight: 'var(--button-primary-line-height)',
	letterSpacing: '0.05em',
	padding: '9px 40px',
	boxShadow: 'none',
	borderWidth: '1px',
	textAlign: 'center',
	zIndex: 1,
	'&:hover': {
		borderColor: 'currentColor',
	},
	'&:focus': {
		borderColor: 'currentColor',
	},
	'&:active': {
		borderColor: 'currentColor',
	},
	'&.Mui-disabled': {},
	'& .MuiButton-startIcon': {
		fill: 'currentColor',
		marginLeft: 0,
		marginRight: '8px',
	},
	'& .MuiSvgIcon-root': {},
	'& .MuiButton-endIcon': {
		fill: 'currentColor',
		marginLeft: '8px',
		marginRight: 0,
		display: 'none',
	},
}
const Button = {
	MuiButton: {
		styleOverrides: {
			root: {
				...fonts.Sora,
				...commonStyles,
				'&.MuiLink-underlineNone': {
					textDecoration: 'none',
				},
			},
			contained: {
				minWidth: '210px',
			},
			containedPrimary: {
				...commonStyles,
				minWidth: '210px',
				color: 'var(--color-secondary)',
				background: 'var(--color-primary)',
				fontFamily: 'var(--font-alternative-regular)',
				fontWeight: 400,
				letterSpacing: '0.05em',
				'&:hover': {
					background: 'var(--color-grey140)',
					borderColor: 'var(--color-grey140)',
				},
				'&:focus': {
					background: 'var(--color-primary)',
					borderColor: 'var(--color-primary)',
				},
				'&:active': {
					background: 'var(--color-grey140)',
					borderColor: 'var(--color-grey140)',
				},
				'&.Mui-disabled': {
					background: 'var(--color-grey90)',
					borderColor: 'var(--color-grey90)',
				},
			},
			containedSecondary: {
				...commonStyles,
				minWidth: '210px',
				color: 'var(--color-primary)',
				background: 'var(--color-secondary)',
				fontFamily: 'var(--font-alternative-regular)',
				letterSpacing: '0.05em',
				'&:hover': {
					background: 'var(--color-grey60)',
					borderColor: 'var(--color-grey60)',
				},
				'&:focus': {
					background: 'var(--color-secondary)',
					borderColor: 'var(--color-secondary)',
				},
				'&:active': {
					background: 'var(--color-grey60)',
					borderColor: 'var(--color-grey60)',
				},
				'&.Mui-disabled': {
					background: 'var(--color-grey90)',
					borderColor: 'var(--color-grey90)',
				},
			},
			outlined: {
				...commonStyles,
				minWidth: '210px',
				'&:hover': {
					borderWidth: '1px',
				},
				'&:focus': {
					borderWidth: '1px',
				},
				'&:active': {
					borderWidth: '1px',
				},
				'&.Mui-disabled': {
					borderWidth: '1px',
				},
			},
			outlinedPrimary: {
				backgroundColor: 'var(--color-secondary)',
				color: 'var(--color-primary)',
				borderColor: 'var(--color-primary)',
				'&:hover': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
				},
				'&:active': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey90)',
					background: 'var(--color-grey90)',
				},
			},
			outlinedSecondary: {
				color: 'var(--color-secondary)',
				borderColor: 'var(--color-secondary)',
				backgroundColor: 'var(--color-primary)',
				'&:hover': {
					backgroundColor: 'var(--color-grey140)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-secondary)',
				},
				'&:focus': {
					backgroundColor: 'var(--color-primary)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-primary)',
				},
				'&:active': {
					backgroundColor: 'var(--color-grey140)',
					color: 'var(--color-secondary)',
					borderColor: 'var(--color-secondary)',
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey110)',
					background: 'var(--color-grey140)',
					borderColor: 'var(--color-grey110)',
				},
			},
			text: {
				...commonStyles,
				padding: '12px 16px',
				minWidth: 'unset',
				minHeight: '40px',
				borderWidth: '0',
				borderRadius: '0px',
				lineHeight: 'var(--spacing-2)',
				position: 'relative',
				overflow: 'hidden',
				textTransform: 'lowercase',
				fontSize: 'var(--font-size-body2)',
				'&::after': {
					content: `""`,
					display: 'block',
					position: 'absolute',
					left: 0,
					right: 0,
					top: 0,
					bottom: 0,
					zIndex: '-1',
					background: 'transparent',
					transform: 'translateY(100%) translateY(-1px)',
					transition: 'all var(--transition)',
				},
				'&:hover': {
					borderWidth: 0,
					'&::after': {
						transform: 'translateY(0)',
					},
				},
				'&:focus': {
					borderWidth: 0,
					'&::after': {
						transform: 'translateY(0)',
						borderWidth: 0,
					},
				},
				'&:active': {
					borderWidth: 0,
					'&::after': {
						transform: 'translateY(0)',
						borderWidth: 0,
					},
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey30)',
				},
			},
			textPrimary: {
				color: 'var(--color-primary)',
				'&::after': {
					background: 'var(--color-primary)',
				},
				'&:hover,&:focus,&:active': {
					color: 'var(--color-secondary)',
				},
			},
			textSecondary: {
				color: 'var(--color-secondary)',
				'&::after': {
					background: 'var(--color-secondary)',
				},
				'&:hover,&:focus,&:active': {
					color: 'var(--color-primary)',
				},
			},
			textLink: {
				color: 'var(--color-primary)',
				borderColor: 'var(--color-primary)',
				'--button-letter-spacing': 'var(--letter-spacing)',
				'&:hover,&:focus,&:active': {
					color: 'var(--color-grey30)',
					borderColor: 'var(--color-grey30)',
				},
				'&.emptyCartButton': {
					'--button-primary-size': 'calc(var(--spacing) * 1.75)',
					'&:hover,&:focus,&:active': {
						color: 'var(--color-primary)',
					},
				},
				'&.Mui-disabled': {
					color: 'var(--color-grey60)',
					borderColor: 'var(--color-grey70)',
				},
			},
			textGrey: {
				'--button-primary-size': 'var(--size-base)',
			},
			sizeSmall: {
				fontSize: 14,
				padding: '12px 20px',
			},
			// textNoLink: {
			// 	...fonts.plusJakartaSansSemiBold,
			// 	fontSize: 'var(--size-base)',
			// 	lineHeight: '24px',
			// 	letterSpacing: 'calc(var(--size-base) + 10%)',
			// 	fontWeight: 600,
			// 	textTransform: 'uppercase',
			// 	minHeight: 'unset',
			// 	minWidth: 'unset',
			// 	[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
			// 		fontSize: 'var(--size-base)',
			// 	},
			// },
		},
	},
	MuiBadge: {
		styleOverrides: {
			colorPrimary: {
				borderWidth: '1px',
			},
			standard: {
				minHeight: 15,
				minWidth: 15,
				transform: 'scale(1) translate(4.5px, 0)',
				fontSize: '8px',
			},
		},
	},
}
export default Button
