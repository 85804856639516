const Form = {
	MuiFormLabel: {
		styleOverrides: {
			asterisk: {
				marginLeft: '0px',
			},
		},
	},
	MuiFormControlLabel: {
		defaultProps: {
			labelPlacement: 'start',
		},
		styleOverrides: {
			root: {
				flexDirection: 'row',
				'&.MuiFormControlLabel-labelPlacementStart': {
					alignItems: 'flex-start',
				},
			},
			label: {
				color: 'var(--color-primary)',
				fontSize: 'var(--font-size-body1)',
			},
		},
	},
}

export default Form
