const Input = {
	MuiFormControl: {
		defaultProps: {
			margin: 'none',
		},
		styleOverrides: {
			root: {
				'& .MuiSelect-icon.MuiSvgIcon-root': {
					top: '12px',
					right: '0',
				},
				'& .MuiInputBase-root .MuiSelect-select': {
					padding: '10px 0',
				},
				'& .phone-input': {
					padding: '7px 0 0',
				},
			},
		},
	},
	MuiInputLabel: {
		styleOverrides: {
			root: {
				position: 'absolute',
				left: 2,
				top: 2,
				transform: 'translate(0, 20px) scale(1)',
				transformOrigin: 'top left',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				maxWidth: '100%',
				padding: '0',
				color: 'var(--color-primary)',
				letterSpacing: '0.08em',
				textTransform: 'lowercase',
				'&.Mui-focused,&.MuiFormLabel-filled': {
					transform: 'translate(0, -1.5px) scale(0.80)',
					color: 'var(--color-primary) !important',
				},
			},
		},
	},
	MuiSelect: {
		defaultProps: {
			variant: 'standard',
		},
		styleOverrides: {
			select: {
				'&.Mui-disabled': {
					backgroundColor: 'var(--color-grey100) !important',
				},
			},
		},
	},
	MuiOutlinedInput: {
		styleOverrides: {
			root: {
				padding: '24px 0 26px',
			},
			input: {
				padding: '21px 24px',
				height: '29px',
			},
			notchedOutline: {
				border: '0px',
			},
			multiline: {
				border: '0px',
				borderRadius: '0px',
				borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
				padding: '18px 0px !important',
			},
		},
	},
	MuiInputAdornment: {
		styleOverrides: {
			root: {
				paddingRight: '0',
				paddingBottom: '0px',
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				'&.MuiInput-root': {
					'&:before,&:after,&:hover:not(.Mui-disabled):before': {
						borderBottom: '1px solid var(--color-primary)',
					},
				},
				'&.MuiInputBase-adornedStart': {
					paddingLeft: '0px',
				},
				'&.MuiInputBase-adornedEnd': {
					paddingRight: '0px',
				},
				'&.Mui-disabled:before': {
					borderBottomStyle: 'solid !important',
					borderBottomColor: 'var(--color-grey100)',
				},
			},
			input: {
				fontSize: 'var(--font-size-body1)',
				textTransform: 'lowercase',
				letterSpacing: '0.08em',
				padding: '10px 2px',
				'&::placeholder': {
					textTransform: 'lowercase',
				},
				'&:-ms-input-placeholder': {
					textTransform: 'lowercase',
				},
				'&::-ms-input-placeholder': {
					textTransform: 'lowercase',
				},
			},
		},
	},
	MuiFormHelperText: {
		styleOverrides: {
			root: {
				fontSize: '10px',
				marginTop: '4px',
				marginBottom: '4px',
			},
		},
	},
	MuiAutocomplete: {
		styleOverrides: {
			hasPopupIcon: {
				'& .MuiInputBase-root.MuiAutocomplete-inputRoot': {
					paddingRight: 0,
					'& input': {
						padding: 'calc(var(--spacing) * 1.25) 0',
						'&::placeholder': {
							color: 'var(--color-primary)',
						},
						'&::-webkit-input-placeholder': {
							color: 'var(--color-primary)',
						},
						'&:-ms-input-placeholder': {
							color: 'var(--color-primary)',
						},
						'&:-moz-input-placeholder': {
							color: 'var(--color-primary)',
						},
					},
				},
				'&.MuiAutocomplete-root .MuiOutlinedInput-root': {
					paddingRight: 0,
					'&.MuiInputBase-adornedEnd': {
						paddingRight: 0,
					},
				},
			},
			hasClearIcon: {
				'.MuiInputBase-root.MuiAutocomplete-inputRoot': {
					paddingRight: 0,
				},
			},
			root: {
				'.MuiInput-root .MuiInput-input': {
					paddingLeft: '4px',
				},
				'.MuiInputLabel-root': {
					display: 'inline-flex',
					'&.Mui-focused,&.MuiFormLabel-filled': {
						color: 'var(--color-primary) !important',
					},
				},
				'.MuiInputAdornment-root': {
					color: 'var(--color-primary) !important',
					'.MuiSvgIcon-root': {
						width: '17px',
						height: '17px',
					},
				},
			},
			clearIndicator: {
				'.MuiSvgIcon-root': {
					width: '17px',
					height: '17px',
				},
			},
		},
	},
}

export default Input
