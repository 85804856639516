export const fontSize = 14
export const htmlFontSize = 14
export const fonts = {
	sora: {
		fontFamily: '"Sora"',
		fontWeight: 400,
	},
	soraBold: {
		fontFamily: '"Sora-Bold"',
		fontWeight: 700,
	},
	manrope: {
		fontFamily: '"Manrope"',
		fontWeight: 400,
	},
}
