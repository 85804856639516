import { default as globalTheme } from '@bluheadless/ui/src/theme/default'

const Chip = {
	MuiChip: {
		styleOverrides: {
			deleteIcon: {
				color: globalTheme.palette.primary.main,
				fontSize: 'var(--font-size-subheadline1)',
				margin: globalTheme.spacing(0, 0, 0, 0.625),
			},
			label: {
				paddingLeft: 0,
				paddingRight: 0,
			},
			root: {
				borderRadius: 12.5,
				fontSize: 'var(--font-size-subheadline1)',
				height: 27,
				paddingLeft: 10,
				paddingRight: 10,
			},
		},
	},
}

export default Chip
