const BHCrossSell = {
	BHCrossSell: {
		defaultProps: {
			showTitle: true,
			skeletonProps: { columnGap: 2 },
			sliderProps: {
				breakpoints: {
					xs: {
						spaceBetween: 2,
					},
					md: {
						spaceBetween: 2,
					},
				},
				controlsPosition: 'default',
				spaceBetween: 2,
			},
		},
	},
}

export default BHCrossSell
