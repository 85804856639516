import { default as defaultTheme, default as globalTheme } from '@bluheadless/ui/src/theme/default'
import { fonts } from '../fonts'

const CssBaseline = {
	MuiCssBaseline: {
		styleOverrides: {
			':root': {
				colorScheme: 'light dark',
				'--spacing': '8px',
				'--spacing-1': 'calc(var(--spacing) * 1)',
				'--spacing-2': 'calc(var(--spacing) * 2)',
				'--spacing-3': 'calc(var(--spacing) * 3)',
				'--spacing-4': 'calc(var(--spacing) * 4)',
				'--spacing-5': 'calc(var(--spacing) * 5)',
				'--spacing-6': 'calc(var(--spacing) * 6)',
				'--spacing-7': 'calc(var(--spacing) * 7)',
				'--spacing-8': 'calc(var(--spacing) * 8)',
				'--spacing-9': 'calc(var(--spacing) * 9)',
				'--spacing-10': 'calc(var(--spacing) * 10)',
				// fallback
				'--size-8': 'var(--spacing)',
				'--size-10': 'calc(var(--spacing) * 1.25)',
				'--size-12': 'calc(var(--spacing) * 1.5)',
				'--size-14': 'calc(var(--spacing) * 1.75)',
				'--size-16': 'var(--spacing-2)',
				'--size-18': 'calc(var(--spacing) * 2.25)',
				'--size-20': 'calc(var(--spacing) * 2.5)',
				'--size-22': 'calc(var(--spacing) * 2.75)',
				'--size-24': 'var(--spacing-3)',
				'--size-28': 'calc(var(--spacing) * 3.5)',
				'--size-30': 'calc(var(--spacing) * 3.75)',
				'--size-32': 'var(--spacing-4)',
				'--size-40': 'calc(var(--spacing) * 5)',
				// letter-spacing
				'--letter-spacing': '0.03em',
				'--letter-spacing-headlinebig': 'var(--letter-spacing)',
				'--letter-spacing-h1': 'var(--letter-spacing)',
				'--letter-spacing-h2': 'var(--letter-spacing)',
				'--letter-spacing-h3': 'var(--letter-spacing)',
				'--letter-spacing-h4': 'var(--letter-spacing)',
				'--letter-spacing-h5': 'var(--letter-spacing)',
				'--letter-spacing-h6': 'var(--letter-spacing)',
				'--letter-spacing-body': 'var(--letter-spacing)',
				// line-height
				'--line-height': '',
				'--line-height-headlinebig': '',
				'--line-height-h1': '',
				'--line-height-h2': '',
				'--line-height-h3': '',
				'--line-height-h4': '',
				'--line-height-h5': '',
				'--line-height-h6': '17.64px',
				'--line-height-body': '',
				// font-family
				'--font-base-regular': '"Manrope"',
				'--font-alternative-regular': '"Sora"',
				'--font-alternative-bold': '"Sora-Bold"',
				// font-size
				'--font-size-headlinebig': defaultTheme.typography.pxToRem(24),
				'--font-size-headlinemedium': defaultTheme.typography.pxToRem(32),
				'--font-size-headlinesmall': defaultTheme.typography.pxToRem(32),
				'--font-size-subheadline1': defaultTheme.typography.pxToRem(16),
				'--font-size-subheadline2': defaultTheme.typography.pxToRem(14),
				'--font-size-headlineDescription': defaultTheme.typography.pxToRem(12),
				'--font-size-h1': defaultTheme.typography.pxToRem(18),
				'--font-size-h2': defaultTheme.typography.pxToRem(20),
				'--font-size-h3': defaultTheme.typography.pxToRem(24),
				'--font-size-h4': defaultTheme.typography.pxToRem(14),
				'--font-size-h5': defaultTheme.typography.pxToRem(12),
				'--font-size-h6': defaultTheme.typography.pxToRem(16),
				'--font-size-body1': defaultTheme.typography.pxToRem(14),
				'--font-size-body2': defaultTheme.typography.pxToRem(12),
				'--font-size-button': defaultTheme.typography.pxToRem(14),
				'--font-size-caption': defaultTheme.typography.pxToRem(10),
				'--font-size-subtitle1': defaultTheme.typography.pxToRem(14),
				// brand
				'--color-brand': '#000000',
				// default
				'--color-primary': '#000000',
				'--color-secondary': '#ffffff',
				// others
				'--color-grey0': '#FEFEFE',
				'--color-grey10': '#FCFCFC',
				'--color-grey20': '#FBFBFB',
				'--color-grey30': '#F8F8F8',
				'--color-grey40': '#F5F5F5',
				'--color-grey50': '#EEEEEE',
				'--color-grey60': '#E8E8E8',
				'--color-grey70': '#DCDCDC',
				'--color-grey80': '#C8C8C8',
				'--color-grey90': '#BCBCBC',
				'--color-grey100': '#A0A0A0',
				'--color-grey110': '#797979',
				'--color-grey120': '#5A5A5A',
				'--color-grey130': '#343434',
				'--color-grey140': '#292929',
				'--color-brown': '#704214',
				'--color-green': '#008000',
				'--color-blue': '#0D31AB',
				'--color-purple': '#800080',
				'--color-pink': '#FFB6C1',
				'--color-red': '#FF0000',
				'--color-orange': '#F28413',
				'--color-yellow': '#FFE400',
				'--color-white': '#FFFFFF',
				'--color-black': '#000000',
				// buttons
				'--button-primary-size': '14px',
				'--button-primary-color': 'var(--color-secondary)',
				'--button-primary-bgcolor': 'var(--color-primary)',
				'--button-primary-hover-bgcolor': 'var(--color-grey10)',
				'--button-primary-line-height': '17px',
				'--button-secondary-size': '14px',
				'--button-secondary-color': 'var(--color-primary)',
				'--button-secondary-bgcolor': 'var(--color-secondary)',
				// animations
				'--transition': '.3s cubic-bezier(0.4, 0, 0.2, 1) 0s',
				'--swiper-theme-color': 'var(--color-primary)',
				// maps
				'--maps-cluster-bg-color': 'var(--color-primary)',
				// various
				'--right-anchored-drawer-width': '500px',

				[defaultTheme.breakpoints.up('md')]: {
					'--font-size-h2': defaultTheme.typography.pxToRem(30),
				},
			},
			html: {
				...fonts.Sora,
				'--size-small': globalTheme.typography.pxToRem(10),
				'--size-base': globalTheme.typography.pxToRem(12),
				'--size-medium': globalTheme.typography.pxToRem(14),
				'--size-big': globalTheme.typography.pxToRem(16),
			},
			body: {
				...fonts.Sora,
				fontFamily: 'var(--font-base-regular)',
				color: 'var(--color-primary)',
				'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
					{
						WebkitBoxShadow: '0 0 0 30px white inset !important',
						WebkitTextFillColor: 'var(--color-primary) !important',
					},

				'& .d-none-desktop': {
					[defaultTheme.breakpoints.up('md')]: {
						display: 'none !important',
					},
				},
				'& .cart-page': {
					'> .MuiContainer-root.main-layout-container': {
						padding: '0 var(--size-8)',
						[defaultTheme.breakpoints.up('md')]: {
							padding: '0 var(--size-14)',
						},
					},
				},

				'& .Magento-PageBuilder-Accordion': {
					'& .MuiAccordion-root:first-of-type': {
						borderTop: 0,
					},
				},
				'& #select-color': {
					'& .MuiButtonBase-root': {
						padding: 'var(--spacing-1)',
						'&.Mui-selected': {
							display: 'none',
						},
						'& .MuiButtonBase-root': {
							padding: 0,
							border: 'none',
							textTransform: 'lowercase',
						},
					},
					'& .option-wrapper': {
						display: 'flex',
						gap: 'var(--spacing-1)',
						alignItems: 'center',
						textTransform: 'lowercase',
						'& .MuiButtonBase-root': {
							padding: 0,
							margin: 0,
							width: '12px',
							height: '12px',
						},
					},
				},
				'& .customer-nested-layout-subcontainer #mui-component-select-reason': {
					whiteSpace: 'normal',
				},
				'& .checkout-page .PaymentMethod-adyen_cc': {
					[defaultTheme.breakpoints.down('md')]: {
						marginLeft: '-33px',
						'& .MuiFormControlLabel-root': {
							display: 'block',
						},
					},
				},
			},
			hr: {
				border: 'none',
				borderTop: '1px solid var(--color-grey60)',
			},
			video: {
				objectFit: 'cover',
			},
		},
	},
}

export default CssBaseline
