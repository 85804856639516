const BHActionAddToCart = {
	BHActionAddToCart: {
		defaultProps: {
			Icon: null,
			StartIcon: null,
		},
	},
}

export default BHActionAddToCart
