// import BHBreadcrumbs from '@/theme/components/bh-breadcrumbs'
// import BHTileBase from '@/theme/components/bh-tile-base'
// import BHTileTooltip from '@/theme/components/bh-tile-tooltip'
// import BHTileSlide from '@/theme/components/bh-tile-slide'
// import BHUpsells from '@/theme/components/bh-upsells'
// import BHRelated from '@/theme/components/bh-related'
import defaultTheme from '@bluheadless/ui/src/theme/default'
import { createTheme as createMuiTheme } from '@mui/material/styles' // keep @mui/material/styles to use default theme of @mui/material
import { createTheme } from '@mui/system'
import Accordion from './theme/components/accordion'
import BHNewsletter from './theme/components/bh-newsletter'
import BHInput from './theme/components/form/bh-input'
import Button from './theme/components/button'
import Chip from './theme/components/chip'
import Checkbox from './theme/components/form/checkbox'
import CssBaseline from './theme/components/css-baseline'
// import DatePicker from './theme/components/form/date-picker'
import Dialog from './theme/components/dialog'
import Form from './theme/components/form/form'
import Input from './theme/components/form/input'
import List from './theme/components/list'
import Paper from './theme/components/paper'
import BHCrossSell from '@/theme/components/bh-crosssell'
// import SvgIcon from './theme/components/svg-icon'
import { fonts } from './theme/fonts'
import BHActionAddToCart from '@/theme/components/bh-action-add-to-cart'

const htmlFontSize = 14
const fontSize = 14
const muiTheme = createMuiTheme({ typography: { htmlFontSize, fontSize } })

const fontFamily = fonts.manrope.fontFamily
const fontFamilyAlternative = fonts.sora.fontFamily

const theme = createTheme(defaultTheme, {
	// Insert here project related theme config
	palette: {
		common: {
			black: '#000000',
			white: '#ffffff',
		},
		primary: {
			main: '#000000',
			light: '#333333',
			dark: '#000000',
			contrastText: '#FFFFFF',
		},
		secondary: {
			main: '#ffffff',
			light: '#fcfcfc',
			dark: '#fcfcfc',
			contrastText: '#000000',
		},
		error: {
			main: '#A21A10',
			light: '#A21A10',
			dark: '#A21A10',
			contrastText: '#fff',
		},
		warning: {
			light: '#F5A623',
			main: '#F5A623',
			dark: '#F5A623',
			contrastText: '#FFFFFF',
		},
		info: {
			light: '#0288D1',
			main: '#0288D1',
			dark: '#0288D1',
			contrastText: '#FFFFFF',
		},
		success: {
			light: '#457030',
			main: '#457030',
			dark: '#457030',
			contrastText: '#FFFFFF',
		},
		grey: {
			main: '#A0A0A0',
			input: '#A0A0A0',
			border: '#EAEAEA',
			label: '#AFAFAF',
			light: '#959595',
			lighter: '#E8E8E8',
			breadcrumbs: '#9F9F9F',
			vat: '#A0A0A0',
			disabled: '#A8A8A8',
			middle: 'var(--color-grey110)',
		},
		footer: {
			bgTop: '#FFFFFF',
			bgMiddleTop: '#FFFFFF',
			bgMiddle: '#FFFFFF',
			bgMiddleBottom: '#FFFFFF',
			bgBottom: '#FFFFFF',
			border: 'var(--color-grey50)',
			color: '#000',
			copyright: '#000',
		},
		background: {
			paper: '#FFFFFF',
			default: '#FFFFFF',
			header: '#FFFFFF',
			card: '#F6F6F6',
		},
		tile: {
			background: '#ffffff',
		},
		button: {
			disabled: '#E1E1E1',
		},
	},
	typography: {
		fontFamily,
		fontFamilyAlternative,
		htmlFontSize,
		fontSize,
		fontWeight: 400,
		fontWeightSemiBold: 600,
		fontWeightBold: 700,
		lineHeight: '16px',
		headlinebig: {
			...fonts.soraBold,
			fontSize: muiTheme.typography.pxToRem(30),
			lineHeight: '40px',
			textTransform: 'uppercase',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(40),
			},
		},
		headlinemedium: {
			...fonts.soraBold,
			fontSize: muiTheme.typography.pxToRem(20),
			lineHeight: '30px',
			textTransform: 'uppercase',
			letterSpacing: '0.05em',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(30),
			},
		},
		subheadline1: {
			...fonts.sora,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '22px',
			fontWeight: 400,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(16),
				lineHeight: 1,
			},
		},
		subheadline2: {
			...fonts.sora,
			fontSize: muiTheme.typography.pxToRem(14),
			lineHeight: '22px',
			fontWeight: 400,
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: muiTheme.typography.pxToRem(16),
				lineHeight: 1,
			},
		},
		h1: {
			...fonts.soraBold,
			fontSize: 'var(--font-size-h1)',
			lineHeight: 1,
			letterSpacing: '0.03em',
			textTransform: 'uppercase',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-h1)',
				lineHeight: '23px',
			},
		},
		h2: {
			...fonts.soraBold,
			fontSize: 'var(--font-size-h2)',
			lineHeight: '30px',
			letterSpacing: '0.03em',
			textTransform: 'uppercase',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: 'var(--font-size-h2)',
				lineHeight: '40px',
			},
		},
		h3: {
			...fonts.sora,
			fontSize: 'var(--font-size-h3)',
			lineHeight: '32px',
			letterSpacing: '0.03em',
			textTransform: 'uppercase',
		},
		h3bold: {
			...fonts.soraBold,
			fontSize: 'var(--font-size-h3)',
			lineHeight: '32px',
			letterSpacing: '0.03em',
			textTransform: 'uppercase',
		},
		h4: {
			...fonts.sora,
			fontSize: 'var(--font-size-h4)',
			letterSpacing: '0.03em',
			lineHeight: '40px',
			textTransform: 'uppercase',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				lineHeight: 1,
				fontSize: 'var(--font-size-h4)',
			},
		},
		h5: {
			...fonts.sora,
			fontSize: 'var(--font-size-h5)',
			lineHeight: '20px',
			letterSpacing: '0.03em',
			textTransform: 'uppercase',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: 'var(--font-size-h5)',
			},
		},
		h6: {
			...fonts.manrope,
			fontSize: 'var(--font-size-h6)',
			lineHeight: '24px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.lg}px)`]: {
				fontSize: 'var(--font-size-h6)',
				lineHeight: 1,
			},
		},
		body1: {
			...fonts.manrope,
			fontSize: 'var(--font-size-body1)',
			lineHeight: '22px',
		},
		body2: {
			...fonts.manrope,
			fontSize: 'var(--font-size-body2)',
			lineHeight: '22px',
		},
		button: {
			...fonts.sora,
			fontSize: 'var(--font-size-button)',
			lineHeight: '16px',
			textTransform: 'uppercase',
		},
		copyright: {
			...fonts.manrope,
			fontSize: 'var(--font-size-body2)',
			lineHeight: '24px',
			fontWeight: 400,
			textAlign: 'center',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				textAlign: 'left',
			},
		},
		caption: {
			...fonts.manrope,
			fontSize: 'var(--font-size-caption)',
			lineHeight: '20px',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				lineHeight: 1,
				fontSize: muiTheme.typography.pxToRem(12),
			},
		},
		overline: {
			...fonts.sora,
		},
		subtitle1: {
			...fonts.sora,
			fontSize: 'var(--font-size-subtitle1)',
			lineHeight: '24px',
			fontWeight: 600,
			textTransform: 'uppercase',
			[`@media screen and (min-width: ${defaultTheme.breakpoints.values.md}px)`]: {
				fontSize: 'var(--font-size-subtitle1)',
			},
		},
	},
	shape: {
		borderRadius: 0,
	},
	scrollbar: `
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 7px;
			height: 5px;
			background-color: var(--color-secondary);
		}

		&::-webkit-scrollbar-track {
			border-radius: 10px;
			background-color: var(--color-secondary);
		}

		&::-webkit-scrollbar-thumb {
			background: var(--color-grey140);
			border-radius: 3.5px;
		},

		&::-webkit-scrollbar-thumb:hover {
			background: var(--color-grey130)
		}
	`,

	scrollbarJsx: {
		scrollbarWidth: 'unset',
		scrollbarColor: 'unset',
		'::-webkit-scrollbar': {
			width: '7px',
			height: '5px',
			backgroundColor: 'var(--color-secondary)',
		},
		'::-webkit-scrollbar-track': {
			borderRadius: '10px',
			backgroundColor: 'var(--color-secondary)',
		},
		'::-webkit-scrollbar-thumb': {
			background: 'var(--color-grey140)',
			borderRadius: '3.5px',
		},
		'::-webkit-scrollbar-thumb:hover': {
			background: 'var(--color-grey130)',
		},
	},
	components: {
		...Accordion,
		...BHActionAddToCart,
		// ...BHBreadcrumbs,
		...BHInput,
		...BHNewsletter,
		// ...BHTileBase,
		// ...BHTileTooltip,
		// ...BHTileSlide,
		// ...BHUpsells,
		...BHCrossSell,
		// ...BHRelated,
		...Button,
		...Checkbox,
		...Chip,
		...CssBaseline,
		// ...DatePicker,
		...Dialog,
		...Form,
		...Input,
		...List,
		...Paper,
		// ...SvgIcon,
	},
})
export default theme
