const Accordion = {
	MuiAccordion: {
		styleOverrides: {
			root: {
				border: 0,
				h6: {
					fontFamily: 'var(--font-alternative-bold)',
					fontSize: 'var(--font-size-body1)',
					lineHeight: '17px',
				},
				'& .MuiAccordionSummary-root': {
					'&.Mui-expanded[aria-expanded=true]': {
						minHeight: 0,
					},
				},
			},
		},
	},
	MuiAccordionSummary: {
		styleOverrides: {
			root: {
				minHeight: 0,
				padding: 'var(--spacing-1) 0',
				'&.Mui-expanded': {
					minHeight: 0,
				},
				'&.Mui-focusVisible': {
					background: 'none',
				},
			},
			expandIconWrapper: {
				transition: 'unset',
				transform: 'unset',
				color: 'var(--color-primary)',
				'& svg': {
					fontSize: 'var(--font-size-body1)',
				},
				'&.Mui-expanded': {
					transform: 'unset',
					color: 'var(--color-primary)',
				},
			},
		},
	},
	MuiAccordionDetails: {
		styleOverrides: {
			root: {
				color: 'var(--color-primary)',
				paddingTop: 'var(--spacing-1)',
				paddingBottom: 'var(--spacing-2)',
				paddingLeft: 0,
				paddingRight: 0,
			},
		},
	},
}

export default Accordion
