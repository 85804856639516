const Dialog = {
	MuiDialog: {
		styleOverrides: {
			root: {
				borderRadius: 0,
				'& .MuiDialog-container .MuiPaper-root': {
					borderRadius: 0,
					scrollbarWidth: 'unset',
					scrollbarColor: 'unset',
					'::-webkit-scrollbar': {
						width: '7px',
						height: '5px',
						backgroundColor: 'var(--color-secondary)',
					},
					'::-webkit-scrollbar-track': {
						borderRadius: '10px',
						backgroundColor: 'var(--color-secondary)',
					},
					'::-webkit-scrollbar-thumb': {
						background: 'var(--color-grey140)',
						borderRadius: '3.5px',
					},
					'::-webkit-scrollbar-thumb:hover': {
						background: 'var(--color-grey130)',
					},
				},
				'&.SizeGuide-root': {
					'& .MuiPaper-root': {
						minHeight: 'initial',
					},
				},
			},
			paper: {
				borderRadius: 0,
			},
		},
	},
	MuiPaper: {
		styleOverrides: {
			root: {
				borderRadius: 0,
				'&.MuiDialog-paper': {},
			},
		},
	},
}

export default Dialog
